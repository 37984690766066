import logo from "./logo.png";
import styles from "./App.module.css";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav className={styles.navbar}>
        <ul>
          <li>
            <Link to={"/"}>Landing Page</Link>
          </li>
          <li>
            <Link to={"salesVideo"}>Sales video</Link>
          </li>
        </ul>
      </nav>

      <div className={styles.root}>
        <Outlet />
      </div>
    </>
  );
};

function Home() {
  return (
    <>
      <h1>Landing Page</h1>
      <article>
        <img src={logo} style={{ width: "250px" }} alt="" />
      </article>
    </>
  );
}
function SalesVideo() {
  return (
    <>
      <h1>SalesVideo</h1>
      <article>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vQ5VFdHpri4?si=-Zb7nRRq64RmmwFw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </article>
    </>
  );
}
function NoPage() {
  return <h1>NoPage</h1>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={`salesVideo`} element={<SalesVideo />} />
          <Route path={"/*"} element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
